/*eslint-disable*/
import React, { useState } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { PlayIcons } from "../../react/Icons";
import { RequestFormModal } from "../../components/modalform";

export const Image = require("../../assets/images/video_icons/dummy_image.png");

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        description="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogTitle="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        ogDescription="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
      />
      <Container>
        <div className="w-100 float-left videos_wrapper">
          <Layout backgroundColor={"bg_videos"} logoFor="ASSISTANT">
            <VideoPlayWrapper />
            <OverviewWrapper />
            <SimiliarVideosWrapper />
            <RequestForm isFooterForm={true} />
          </Layout>
        </div>
      </Container>
    </>
  );
}

const VideoPlayWrapper = () => {
  return (
    <div className="back_features_hide cc_teams_wrapper">
      <section className="main-slider feature_inner_page  bg_feature_slider bg_feature_slider_wrapper trial_page_img">
        <div className="feature_bg_img bg_videos" />
        <div className="container">
          <div className="row">
            <div className="back_features">
              <div className="back_features_page">
                <a
                  href="/conversational-ai-platform/features"
                  className="url_manipulation"
                >
                  <span>←</span>Back to Videos
                </a>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="main-slider-left">
                <h1>
                  Enhance your Freshdesk experience with Slack or Microsoft
                  Teams chatbot and workflow automation, from Workativ.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header pl-0 pr-0">
            <div className="features_change_img">
              <div className="features_change_img-center">
                <iframe
                  className="youtube_video"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/urJRpF3G4Ws?rel=0&amp;controls=1&amp;showinfo=0&autoplay=1&mute=0"
                  allow="autoplay; encrypted-media"
                  title="YouTube video player"
                  frameborder="0"
                  allowfullscreen
                  allowtransparency="true"
                  style="background: #FFFFFF;"
                ></iframe>

                {/* <iframe width="770" height="434" src="//www.youtube.com/embed/DNaVY8rh0PQ?theme=light&modestbranding=1&autohide=1&showinfo=0&controls=1&rel=0&vq=hd1080&autoplay=1&mute=1" frameborder="0" allowfullscreen></iframe> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const OverviewWrapper = () => {
  return (
    <div className="overview_wrapper">
      <section class="skit_contactus sign_up-form_us demo_page">
        <div class="container signup_form">
          <div class="row beta_change_form">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
              <h3>An overview</h3>
              <p>
                Combining the Freshdesk ticketing tool with Workativ Assistant,
                you make sure your employees have access to an interactive IT
                self-service interface instead of static portals. Let’s take a
                look at what all you can do with Freshdesk + Workativ Assistant.
              </p>
              <h6>
                Freshdesk actions that can be automated with Workativ Assistant
              </h6>
              <ul>
                <li>Creating a Freshdesk support ticket</li>
                <li> Retrieving a Freshdesk ticket’s details</li>
                <li>Updating a Freshdesk ticket</li>
                <li>Deleting a Freshdesk ticket</li>
                <li>Adding new contact</li>
                <li>Updating a contact’s details</li>
                <li>Removing a contact</li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 demo_page_right">
              <div className="overview_right_wrpper">
                <ResourcesWrapper />
                <GetStartedWrapper />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ResourcesWrapper = () => {
  return (
    <div className="resource_wrapper">
      <h6>Resources</h6>
      <ul>
        <li>
          <a href="https://blog.workativ.com/top-9-reasons-to-build-a-digital-workplace-workativ/">
            Sign up with Workativ Assistant
          </a>
        </li>
        <li>
          <a href="https://blog.workativ.com/5-benefits-of-introducing-hr-bots-to-support-remote-workers/">
            5 Benefits of Introducing HR Bots to Support Remote Workers
          </a>
        </li>
      </ul>
    </div>
  );
};

const GetStartedWrapper = () => {
  const [plan, setPlan] = useState("");

  const [showThanksPage, setShowThanksPage] = useState(false);
  return (
    <div className="get_started_wrapper">
      <h5>
        Kickstart Your Employee Support Transformation With Workativ’s IT Help
        Desk Chatbot Today!
      </h5>
      <button
        plan={plan}
        onClick={() => setPlan("homepage")}
        className="home_page_sign url_manipulation"
      >
        Get Started
      </button>

      {plan == "" ? null : (
        <RequestFormModal
          setPlan={setPlan}
          plan={plan}
          showThanksPage={showThanksPage}
          setShowThanksPage={setShowThanksPage}
        />
      )}
    </div>
  );
};

const SimiliarVideosWrapper = () => {
  return (
    <section className="similar_videos_wrapper">
      <div className="utube_videos col-12 col-lg-12 ">
        <div className="container">
          <div className="row">
            <h3>Similar videos you might like</h3>
            <div className="utube_rows">
              <div className="utube_col_4">
                <div className="utube_iframe">
                  <div className="position-relative w-100">
                    <span>
                      <PlayIcons />
                    </span>
                    <img src={Image} />
                  </div>
                  <p className="utube_content">
                    AI Conversational Bot for IT Help desk - conversational
                    dialogs
                  </p>
                </div>
              </div>
              <div className="utube_col_4">
                <div className="utube_iframe">
                  <div className="position-relative w-100">
                    <span>
                      <PlayIcons />
                    </span>
                    <img src={Image} />
                  </div>
                  <p className="utube_content">
                    AI Conversational Bot for IT Help desk - conversational
                    dialogs
                  </p>
                </div>
              </div>
              <div className="utube_col_4">
                <div className="utube_iframe">
                  <div className="position-relative w-100">
                    <span>
                      <PlayIcons />
                    </span>
                    <img src={Image} />
                  </div>
                  <p className="utube_content">
                    AI Conversational Bot for IT Help desk - conversational
                    dialogs
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
